<template>
  <div class="flex-col profile">
    <div class="terms-title">
      <h1>{{ name }} : Referral Terms & Conditions</h1>
      <v-divider />
    </div>
    <div class="flex-col terms-text-box">
      <v-textarea id="terms-textarea-terms" v-model="terms" solo class="box" />
      <div class="bottom">
        <v-btn
          id="terms-button-save"
          class="btn-primaryaction"
          :loading="loading"
          @click="submit"
        >
          Save
        </v-btn>
      </div>
    </div>
    <v-snackbar
      v-model="display"
      :color="snackType ? 'success' : 'error'"
      :multi-line="'multi-line'"
      :timeout="snackType ? 1000 : 0"
      :vertical="'vertical'"
      class="toast"
      :type="snackType ? 'success' : 'error'"
    >
      {{ msg }}
      <v-btn id="terms-button-close" dark flat @click="display = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import companies from '@/services/companies'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    mode: {
      type: String,
      default: 'company-terms',
    },
  },
  data() {
    return {
      name: '',
      display: false,
      snackType: false,
      termId: null,
      terms: '',
      msg: '',
      id: null,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    $route: {
      deep: true,
      async handler() {
        await this.sync()
      },
    },
  },
  async mounted() {
    await this.sync()
  },
  methods: {
    async sync() {
      const company = JSON.parse(JSON.stringify(this.currentUser.company))
      this.id = company.companyId
      const companyAPIResponse = await companies.getCompany(this.id)
      const companyResponseData = companyAPIResponse.data.company
      this.name = companyResponseData.name

      await this.fetchTerms()
    },
    async fetchTerms() {
      try {
        const data = await companies.getReferralTerms(this.id)
        const { termId, body } = data.data

        this.terms = body
        this.termId = termId
      } catch {
        this.displaySnackBar('Error in retrieving terms', false)
      }
    },
    displaySnackBar(msg, type) {
      this.loading = false
      this.snackType = type
      this.display = true
      this.msg = msg

      setTimeout(() => {
        this.display = false
      }, 2000)
    },
    async submit() {
      this.loading = true
      try {
        const payload = {
          id: this.id,
          payload: {
            termId: this.termId,
            body: this.terms,
            companyId: this.id,
            modifiedByID: null,
          },
        }

        await companies.modifyReferralTerms(payload)
      } catch {
        await this.fetchTerms()
        this.displaySnackBar('Error in modifying terms', false)
        return
      }

      this.displaySnackBar('Successfully changed terms', true)
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin: 10px 0 10px 0;
  text-align: center;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.profile {
  position: relative;
  padding: 24px;
  margin: 0 24px 48px 24px;
  background: $white;
  border-radius: 15px;
}

.box {
  padding: 10px 90px 0 90px;
}

.bottom {
  display: flex;
  justify-content: center;
}

.toast {
  position: absolute;
  bottom: 2%;
}

.profile ::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}

.terms-text-box ::v-deep .v-input__slot {
  box-shadow: none !important;
}

.terms-text-box ::v-deep textarea {
  height: 100px;
  min-height: 500px;
  padding-bottom: 40px;
  overflow-y: scroll;
  resize: none;
}

.terms-title {
  padding: 6px;

  h1 {
    margin-bottom: 26px;
  }
}
</style>
